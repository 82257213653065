// src/pages/AlbumReleasesPage.jsx

import React from 'react';
import Footer from '../components/footer';
import { FaSpotify, FaApple, FaAmazon, FaYoutube, FaDeezer, FaMusic } from 'react-icons/fa'; // FaMusic será usado para Tidal

const platformIcons = {
  'Spotify': <FaSpotify className="text-green-500" />,
  'Apple Music': <FaApple className="text-black" />,
  'Amazon Music': <FaAmazon className="text-yellow-500" />,
  'YouTube Music': <FaYoutube className="text-red-500" />,
  'Deezer': <FaDeezer className="text-blue-500" />,
  'Tidal': <FaMusic className="text-black" />, // Usamos FaMusic como un ícono genérico
};

const upcomingReleases = [
  {
    title: 'Ecos del Corazón',
    releaseDate: '2024-09-15',
    releaseTime: '12:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'YouTube Music'],
  },
  {
    title: 'Destellos del Destino',
    releaseDate: '2024-11-14',
    releaseTime: '10:00 AM',
    platforms: ['Spotify', 'Apple Music', 'Deezer'],
  },
  {
    title: 'Caminos Entrelazados',
    releaseDate: '2025-01-13',
    releaseTime: '03:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'Tidal'],
  },
  {
    title: 'Susurros del Viento',
    releaseDate: '2025-03-14',
    releaseTime: '04:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'YouTube Music'],
  },
  {
    title: 'Libertad',
    releaseDate: '2025-05-13',
    releaseTime: '11:00 AM',
    platforms: ['Spotify', 'Apple Music', 'Deezer'],
  },
  {
    title: 'Rieles de Amor',
    releaseDate: '2025-07-12',
    releaseTime: '02:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'Tidal'],
  },
  {
    title: 'Vivencias',
    releaseDate: '2025-09-10',
    releaseTime: '01:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'YouTube Music'],
  },
  {
    title: 'Raíces del Pueblo',
    releaseDate: '2025-11-09',
    releaseTime: '10:00 AM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'Tidal'],
  },
  {
    title: 'Harmony',
    releaseDate: '2026-01-08',
    releaseTime: '12:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Deezer'],
  },
  {
    title: 'Voces de la Infancia',
    releaseDate: '2026-03-09',
    releaseTime: '03:00 PM',
    platforms: ['Spotify', 'Apple Music', 'Amazon Music', 'YouTube Music'],
  },
];

const AlbumReleasesPage = () => {
  return (
    <div className="bg-[#f5f5f5] min-h-screen pt-20 flex flex-col">
      <div className="container mx-auto px-8 py-16 flex-grow">
        <h1 className="text-2xl font-bold text-center text-[#0e143d] mb-12">
          Próximos Lanzamientos
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {upcomingReleases.map((release, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-2xl shadow-lg transition-shadow hover:shadow-2xl"
            >
              <h2 className="text-2xl font-semibold text-[#1e1e1e] mb-2">
                {release.title}
              </h2>
              <p className="text-sm text-gray-500 mb-4">
                {new Date(release.releaseDate).toLocaleDateString('es-ES', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })} 
                {' • '} {release.releaseTime}
              </p>
              <div className="flex space-x-4 mt-4">
                {release.platforms.map((platform, idx) => (
                  <div key={idx} className="text-3xl">
                    {platformIcons[platform]}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AlbumReleasesPage;
