// Releases.js
import React from 'react';
import DesktopCarousel from '../components/Desktop_albums_carousel/Desktop';
import MobileCarousel from '../components/Desktop_albums_carousel/Mobile';

const Releases = () => {
  return (
    <section className="bg-gray-200 text-gray-900 py-12 px-5">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="md:text-4xl text-2xl font-extrabold mb-4 text-gray-800">Últimos Lanzamientos</h2>
          <p className="text-lg text-gray-600">Descubre nuestras últimas canciones y álbumes disponibles en todas las plataformas digitales.</p>
        </div>

        {/* Mostrar DesktopCarousel en pantallas grandes */}
        <div className="hidden lg:block">
          <DesktopCarousel />
        </div>

        {/* Mostrar MobileCarousel en pantallas pequeñas */}
        <div className="lg:hidden">
          <MobileCarousel />
        </div>
      </div>
    </section>
  );
};

export default Releases;
