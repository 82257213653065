import { FaEarlybirds, FaMusic, FaFilm, FaTshirt, FaVideo, FaCrown } from 'react-icons/fa';

const SubscriptionPlans = () => {
  return (
    <section id="subscription" className="bg-gray-200 text-gray-900 py-20 px-5">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="md:text-4xl  text-2xl font-bold mb-10">Nuestros Planes de Suscripción</h2>
        <p className="text-xl mb-16">Elige el plan que mejor se adapte a ti y únete a nosotros en este viaje musical.</p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {/* Acceso Anticipado */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-gray-200 hover:border-electricBlue">
            <FaEarlybirds size={50} className="text-electricBlue mx-auto mb-4 transform hover:scale-105 transition-transform duration-300" />
            <h3 className="text-2xl font-semibold mb-4">Acceso Anticipado</h3>
            <p className="mb-4">Acceso exclusivo a nueva música dos semanas antes de su lanzamiento oficial.</p>
            <ul className="mb-6 space-y-2">
              <li>- Acceso temprano a lanzamientos</li>
              <li>- Participación en votaciones</li>
              <li>- Contenido exclusivo</li>
            </ul>
            <a href="https://www.patreon.com/tu_pagina" className="block bg-gradient-to-r from-electricBlue to-blue-600 text-center py-3 px-6 rounded-lg font-bold text-white hover:shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300">Únete a Patreon</a>
          </div>

          {/* Canciones Personalizadas */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-gray-200 hover:border-electricBlue">
            <FaMusic size={50} className="text-electricBlue mx-auto mb-4 transform hover:scale-105 transition-transform duration-300" />
            <h3 className="text-2xl font-semibold mb-4">Canciones Personalizadas</h3>
            <p className="mb-4">Recibe canciones personalizadas basadas en tus preferencias y dedicatorias.</p>
            <ul className="mb-6 space-y-2">
              <li>- Canciones a medida</li>
              <li>- Mensajes personalizados</li>
              <li>- Regalos únicos</li>
            </ul>
            <a href="https://www.patreon.com/tu_pagina" className="block bg-gradient-to-r from-electricBlue to-blue-600 text-center py-3 px-6 rounded-lg font-bold text-white hover:shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300">Únete a Patreon</a>
          </div>

          {/* Detrás de Escenas */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-gray-200 hover:border-electricBlue">
            <FaFilm size={50} className="text-electricBlue mx-auto mb-4 transform hover:scale-105 transition-transform duration-300" />
            <h3 className="text-2xl font-semibold mb-4">Detrás de Escenas</h3>
            <p className="mb-4">Accede a contenido exclusivo detrás de cámaras y el proceso creativo.</p>
            <ul className="mb-6 space-y-2">
              <li>- Videos detrás de escenas</li>
              <li>- Acceso a ensayos</li>
              <li>- Charlas con el equipo</li>
            </ul>
            <a href="https://www.patreon.com/tu_pagina" className="block bg-gradient-to-r from-electricBlue to-blue-600 text-center py-3 px-6 rounded-lg font-bold text-white hover:shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300">Únete a Patreon</a>
          </div>

          {/* Merchandising Exclusivo */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-gray-200 hover:border-electricBlue">
            <FaTshirt size={50} className="text-electricBlue mx-auto mb-4 transform hover:scale-105 transition-transform duration-300" />
            <h3 className="text-2xl font-semibold mb-4">Merchandising Exclusivo</h3>
            <p className="mb-4">Accede a productos exclusivos y ediciones limitadas.</p>
            <ul className="mb-6 space-y-2">
              <li>- Camisetas personalizadas</li>
              <li>- Posters exclusivos</li>
              <li>- Accesorios únicos</li>
            </ul>
            <a href="https://www.patreon.com/tu_pagina" className="block bg-gradient-to-r from-electricBlue to-blue-600 text-center py-3 px-6 rounded-lg font-bold text-white hover:shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300">Únete a Patreon</a>
          </div>

          {/* Meet & Greet Virtual */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-gray-200 hover:border-electricBlue">
            <FaVideo size={50} className="text-electricBlue mx-auto mb-4 transform hover:scale-105 transition-transform duration-300" />
            <h3 className="text-2xl font-semibold mb-4">Meet & Greet Virtual</h3>
            <p className="mb-4">Participa en sesiones de Meet & Greet virtuales y conecta con nosotros en directo.</p>
            <ul className="mb-6 space-y-2">
              <li>- Sesiones en vivo</li>
              <li>- Preguntas y respuestas</li>
              <li>- Charlas exclusivas</li>
            </ul>
            <a href="https://www.patreon.com/tu_pagina" className="block bg-gradient-to-r from-electricBlue to-blue-600 text-center py-3 px-6 rounded-lg font-bold text-white hover:shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300">Únete a Patreon</a>
          </div>

          {/* Membresía VIP */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-gray-200 hover:border-electricBlue">
            <FaCrown size={50} className="text-electricBlue mx-auto mb-4 transform hover:scale-105 transition-transform duration-300" />
            <h3 className="text-2xl font-semibold mb-4">Membresía VIP</h3>
            <p className="mb-4">Obtén acceso a todos los beneficios anteriores y mucho más con nuestra membresía VIP.</p>
            <ul className="mb-6 space-y-2">
              <li>- Acceso a todos los contenidos</li>
              <li>- Invitaciones a eventos exclusivos</li>
              <li>- Beneficios adicionales</li>
            </ul>
            <a href="https://www.patreon.com/tu_pagina" className="block bg-gradient-to-r from-electricBlue to-blue-600 text-center py-3 px-6 rounded-lg font-bold text-white hover:shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300">Únete a Patreon</a>
          </div>
        </div>

       
      </div>
    </section>
  );
};

export default SubscriptionPlans;
