import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/Hero';
import SubscriptionPlans from './components/subscription';
import Testimonials from './components/testimonials';
import Releases from './components/releases';
import News from './pages/News';
import Albums from './components/album';
import ContactForm from './components/contact';
import Footer from './components/footer';
import Faq from './pages/faqs';
import NotFound from './components/notfound';
import AboutUs from './components/About';
import MusicPlayer from './pages/musicPlayer';
import EcosCorazon from './components/Ecos_corazon';
import DestelloDestino from './components/Destellos_destino';
import CaminosEntrelazados from './components/Caminos_Entrelazados';
import SusurrosViento from './components/Susurros_Viento';
import FeaturesAndBenefits from './components/Benefits';
import Comparason from './components/comparison';
import NewsletterForm from './components/Newsletter';
import Services from '../src/components/Services';
import BackToTopButton from './components/backToTop';
import ContactPage from './pages/Contact';
import ReviewPage from './pages/ReviewPage';
import AlbumReleasesPage from './pages/AlbumReleasePPage';
import Modal from './components/PromoBanner';  // Importa el componente Modal

function App() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);  // Limpia el temporizador al desmontar
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />
            <Services />
            <SubscriptionPlans />
            <FeaturesAndBenefits />
            <Comparason />
            <Testimonials />
            <Releases />
            <NewsletterForm />
            <ContactForm />
            <Footer />
          </>
        } />
        <Route path="/news" element={<News />} />
        <Route path="/albums" element={<Albums />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/reviews" element={<ReviewPage />} />
        <Route path="/album-releases" element={<AlbumReleasesPage />} />
        <Route path="/music" element={<MusicPlayer />} />
        <Route path="/albums/ecos-del-corazon" element={<EcosCorazon />} />
        <Route path="/albums/destellos-del-destino" element={<DestelloDestino />} />
        <Route path="/albums/caminos-entrelazados" element={<CaminosEntrelazados />} />
        <Route path="/albums/susurro-del-viento" element={<SusurrosViento />} />
      </Routes>
      <BackToTopButton />
      <Modal show={showModal} onClose={handleCloseModal} />  {/* Renderiza el modal */}
    </div>
  );
}

export default App;
