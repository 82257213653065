// src/components/BackToTopButton.js
import React, { useState, useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) { // Cambia este valor para ajustar cuándo aparece el botón
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    showButton && (
      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 bg-electricBlue text-white p-3 z-[1000] rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        aria-label="Volver al inicio"
      >
        <FaChevronUp size={24} />
      </button>
    )
  );
};

export default BackToTopButton;
