import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import ContactSuccess from './ContactSuccess';
import { FaWhatsapp, FaMapMarkerAlt, FaClock } from 'react-icons/fa';

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mpwabbly");

  if (state.succeeded) {
    return <ContactSuccess />;
  }

  return (
    <section id="contact" className="bg-gray-200 py-12 px-5">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Formulario de Contacto */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-8 text-darkBlue text-center">Contacto</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block text-left text-gray-700 mb-2">Nombre</label>
                <input 
                  type="text" 
                  id="name"
                  name="name"
                  placeholder="Tu nombre completo" 
                  className="w-full p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-electricBlue transition duration-300"
                  required
                />
                <ValidationError
                  prefix="Nombre"
                  field="name"
                  errors={state.errors}
                />
              </div>
              <div>
                <label className="block text-left text-gray-700 mb-2">Correo Electrónico</label>
                <input 
                  type="email" 
                  id="email"
                  name="email"
                  placeholder="Tu correo electrónico" 
                  className="w-full p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-electricBlue transition duration-300"
                  required
                />
                <ValidationError
                  prefix="Correo Electrónico"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div>
                <label className="block text-left text-gray-700 mb-2">Mensaje</label>
                <textarea 
                  id="message"
                  name="message"
                  placeholder="Escribe tu mensaje aquí..." 
                  className="w-full p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-electricBlue transition duration-300"
                  rows="4"
                  required
                ></textarea>
                <ValidationError
                  prefix="Mensaje"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>
            <button 
              type="submit" 
              disabled={state.submitting}
              className="bg-electricBlue text-white py-3 px-6 rounded-lg font-bold hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-electricBlue"
            >
              Enviar
            </button>
          </form>
        </div>

        {/* Información de Contacto */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-6 text-darkBlue text-center">Cómo Contactarnos</h2>
          <p className="text-lg text-gray-700 mb-6">
            Para más información o consultas, puedes comunicarte con nosotros a través del siguiente formulario o enviarnos un mensaje por WhatsApp.
          </p>
          <div className="space-y-4 mb-6">
            <div className="flex items-center">
              <FaWhatsapp className="text-green-500 text-2xl mr-4" />
              <a
                href="https://wa.me/18093052197" // Reemplaza con tu número de WhatsApp
                className="text-lg font-semibold text-electricBlue hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Enviar Mensaje por WhatsApp
              </a>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-gray-700 text-2xl mr-4" />
              <p className="text-lg text-gray-700"> Avenida Jose Armando Bermudes #6, Santiago, República Dominicana</p>
            </div>
            <div className="flex items-center">
              <FaClock className="text-gray-700 text-2xl mr-4" />
              <p className="text-lg text-gray-700">Lunes a Viernes: 8:00 AM - 7:00 PM</p>
            </div>
          </div>
          <p className="text-lg text-gray-700">
            Nuestro equipo se compromete a responder todas las consultas en un plazo de 48 horas. ¡Gracias por tu paciencia!
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
