import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="bg-white p-5 rounded-lg shadow-lg mb-4 transition-all duration-300 ease-in-out hover:shadow-xl">
      <div 
        className="flex justify-between items-center cursor-pointer" 
        onClick={onClick}
      >
        <h3 className="text-lg font-semibold text-electricBlue">{question}</h3>
        {isOpen ? <FaMinus className="text-electricBlue" /> : <FaPlus className="text-electricBlue" />}
      </div>
      {isOpen && <p className="text-gray-700 mt-3">{answer}</p>}
    </div>
  );
};

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FAQItem;
