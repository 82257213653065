import React, { useState, useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Confetti from 'react-confetti';

const ContactSuccess = () => {
  const [confettiVisible, setConfettiVisible] = useState(true);

  // Hide confetti after a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiVisible(false);
    }, 5000); // Confetti visible for 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <section id="contact" className="bg-light-gray py-16 px-4 relative overflow-hidden">
      {confettiVisible && <Confetti />}
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-3xl w-full px-4">
          <div className="text-center mb-12 bg-white p-8 rounded-lg shadow-lg">
            <div className="text-blue-600 mb-4">
              <FaCheckCircle size={48} className="mx-auto" />
            </div>
            <h2 className="text-4xl font-extrabold text-dark-blue mb-4">¡Mensaje Enviado!</h2>
            <p className="text-lg text-gray-700">¡Gracias por contactarnos! Nos pondremos en contacto contigo pronto.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSuccess;
