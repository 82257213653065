// src/pages/ContactPage.js
import React from 'react';
import Contact from '../components/contact';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';

const ContactPage = () => {
  return (
    <div className="pt-20">
        <Navbar />
      <Contact />
        <Footer />
    </div>
  );
};

export default ContactPage;
