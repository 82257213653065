import React, { useState } from 'react';

const NewsletterSection = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes añadir la lógica para enviar el email
    setSubmitted(true);
    setEmail('');
  };

  return (
    <section className="bg-[#020c25] py-12 px-5">
      <div className="bg-light-gray text-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-auto">
        {submitted ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">¡Gracias por suscribirte!</h2>
            <p>Recibirás notificaciones sobre nuestros lanzamientos.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <h2 className="text-2xl font-bold  text-center mb-4">¡Suscríbete a nuestras novedades!</h2>
            <p className="mb-6 text-center">
              Suscríbete para que te notifiquemos cuando hagamos lanzamientos.
            </p>
            <input
              type="email"
              placeholder="Tu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-3 rounded-md border-2 border-electricBlue bg-white text-gray-900 placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-electricBlue mb-4"
            />
            <button
              type="submit"
              className="w-full bg-electricBlue text-white p-3 rounded-md font-semibold hover:bg-dark-blue transition-colors"
            >
              Suscribirse
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default NewsletterSection;
