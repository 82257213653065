import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import HeroImage from '../assets/hero.png';
import '../App.css';
import WhatsAppButton from './Whatapp';
import PromoBanner from './PromoBanner';

const HeroSection = () => {
  const backgroundRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      if (window.innerWidth > 768) {
        const scrollY = window.scrollY;
        const speed = 0.5;
        if (backgroundRef.current) {
          backgroundRef.current.style.transform = `translateY(${scrollY * speed}px)`;
        }
      }
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    
    <section className="relative w-full h-screen flex flex-col items-center justify-center text-center bg-cover bg-center"
    
             style={{ backgroundImage: `url(${HeroImage})` }}>
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#16163c] via-[#022549] to-[#004080] z-[-1]"></div> {/* Gradiente de fondo */}
      <div className="bg-black bg-opacity-60 z-0 w-full h-full absolute top-0 left-0"></div>
      <div className="z-10 p-5">
        <h1 className="text-5xl md:text-6xl font-bold bg-gradient-to-r from-electricBlue via-indigo-500 to-purple-600 text-transparent bg-clip-text shadow-lg">
          Descubre el Sonido de la Innovación
        </h1>
        <p className="text-xl text-gray-300 mt-4 md:text-2xl">Únete a nosotros en un viaje musical como ningún otro.</p>
        <div className="mt-8">
          <button onClick={() => scrollToSection('subscription')}
                  className="inline-block bg-transparent border-2 border-electricBlue text-white font-bold mb-8 py-3 px-6 rounded-lg uppercase shadow-lg hover:bg-electricBlue hover:text-white hover:shadow-xl transform hover:scale-105 transition duration-300">
            Explorar Ahora
          </button>
          <Link to="/music"
                className="inline-block bg-transparent border-2 border-electricBlue text-white font-bold py-3 px-6 rounded-lg uppercase shadow-lg hover:bg-electricBlue hover:text-white hover:shadow-xl transform hover:scale-105 transition duration-300 ml-4">
            Escuchar Música
          </Link>
        </div>
      </div>
      

      <WhatsAppButton />
    </section>
  );
};

export default HeroSection;
