import React from 'react';
import { FaYoutube, FaSpotify, FaApple, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Asegúrate de que React Router esté configurado correctamente
import parentCompanyLogo from '../assets/NineStarLogo.svg'; // Asegúrate de reemplazar esto con la ruta correcta de tu logo

const Footer = () => {
  return (
    <footer className="bg-[#020c25] text-white py-10">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
        {/* Columna 1 */}
        <div>
          <h3 className="text-lg font-semibold text-electricBlue mb-4">Sobre Nosotros</h3>
          <p className="text-softGray">
            Somos una compañía de música innovadora que utiliza IA para interpretar canciones con letras originales creadas por nosotros.
          </p>
        </div>
        {/* Columna 2 */}
        <div>
          <h3 className="text-lg font-semibold text-electricBlue mb-4">Enlaces Útiles</h3>
          <ul>
            <li className="mb-2"><a href="/" className="text-softGray hover:text-electricBlue">Inicio</a></li>
            <li className="mb-2"><a href="/news" className="text-softGray hover:text-electricBlue">Noticias</a></li>
            <li className="mb-2"><a href="/about" className="text-softGray hover:text-electricBlue">Sobre Nosotros</a></li>
            <li className="mb-2"><a href="/contact" className="text-softGray hover:text-electricBlue">Contacto</a></li>
          </ul>
        </div>
        {/* Columna 3 */}
        <div className="flex flex-col items-center md:items-center">
          <h3 className="text-lg font-semibold text-electricBlue mb-4">Síguenos</h3>
          <div className="flex justify-center md:justify-center space-x-4 mb-4">
            <a href="https://www.youtube.com/tu_canal" className="text-electricBlue hover:text-gray-300">
              <FaYoutube size={24} />
            </a>
            <a href="https://www.spotify.com/tu_canal" className="text-electricBlue hover:text-gray-300">
              <FaSpotify size={24} />
            </a>
            <a href="https://www.apple.com/music/tu_canal" className="text-electricBlue hover:text-gray-300">
              <FaApple size={24} />
            </a>
            <a href="https://www.instagram.com/tu_cuenta" className="text-electricBlue hover:text-gray-300">
              <FaInstagram size={24} />
            </a>
          </div>
          <Link to="https://ninestarunited.com" className="flex flex-col items-center">
            <img src={parentCompanyLogo} alt="Nine Star United" className="h-16 w-auto mb-2" />
            <span
              className="text-softGray bg-gradient-to-r from-electricBlue via-indigo-500 to-purple-600 bg-clip-text text-transparent font-semibold">
              A Nine Star United company
            </span>
          </Link>
        </div>
      </div>
      <div className="border-t border-gray-800 mt-8 pt-4">
        <p className="text-center text-softGray">&copy; 2024 EcoMusic. Todos los derechos reservados.</p>
      </div>
      <div className="border-t border-gray-800 mt-8 pt-4">
        <Link to="https://ninestarunited.com"> <p className="text-center text-electricBlue text-1xl">Desarrollado por <span className="text-white">Nine Star United</span></p></Link>
      </div>
    </footer>
  );
};

export default Footer;
