import React, { useState } from 'react';
import ReviewForm from '../components/ReviewForm';
import ReviewList from '../components/ReviewList';
import Footer from '../components/footer';

const initialReviews = [
  { id: 1, author: 'Juan Pérez', text: 'Excelente servicio, muy satisfecho.' },
  { id: 2, author: 'Ana Martínez', text: 'Muy buena atención al cliente.' },
  // Agrega más reseñas aquí para probar la paginación
];

const ReviewPage = () => {
  const [reviews, setReviews] = useState(initialReviews);

  const addReview = (review) => {
    setReviews([...reviews, { id: reviews.length + 1, ...review }]);
  };

  return (
    <div className="container mx-auto pt-28 ">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-extrabold mb-6 text-center text-[#020c25]">Deja tu Reseña</h2>
          <ReviewForm onAddReview={addReview} />
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-extrabold mb-6 text-[#020c25]">Reseñas de Usuarios</h2>
          <ReviewList reviews={reviews} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewPage;
