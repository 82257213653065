import React from 'react';
import { FaNewspaper, FaMusic, FaBroadcastTower } from 'react-icons/fa';
import NewsImage1 from '../assets/hero.png';
import NewsImage2 from '../assets/her2.png';
import NewsImage3 from '../assets/guitar.png';

const NewsArticles = () => {
  const articles = [
    {
      id: 1,
      title: 'Lanzamiento del Nuevo Álbum',
      description: 'Descubre todo sobre nuestro último álbum lanzado en todas las plataformas digitales. Interpretado por IA y letras originales.',
      image: NewsImage1,
      icon: <FaMusic size={30} className="text-electricBlue" />,
      link: '/news/new-album'
    },
    {
      id: 2,
      title: 'Entrevista Exclusiva',
      description: 'Lee la entrevista exclusiva sobre el proceso creativo detrás de nuestras letras y cómo la IA interpreta nuestra música.',
      image: NewsImage2,
      icon: <FaNewspaper size={30} className="text-electricBlue" />,
      link: '/news/exclusive-interview'
    },
    {
      id: 3,
      title: 'Concierto Virtual',
      description: 'Únete a nosotros en nuestro próximo concierto virtual y disfruta de la música en vivo interpretada por IA con letras originales.',
      image: NewsImage3,
      icon: <FaBroadcastTower size={30} className="text-electricBlue" />,
      link: '/news/virtual-concert'
    }
  ];

  return (
    <section className="bg-lightGray py-36 px-5 md:px-10 lg:px-20">
      <h2 className="text-4xl font-bold text-center text-darkBlue mb-12">Últimas Noticias</h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {articles.map((article) => (
          <div key={article.id} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
            <img src={article.image} alt={article.title} className="w-full h-48 object-cover" />
            <div className="p-6">
              <div className="flex items-center mb-4">
                {article.icon}
                <h3 className="text-2xl font-semibold ml-3 text-darkBlue">{article.title}</h3>
              </div>
              <p className="text-gray-700 mb-4">{article.description}</p>
              <a href={article.link} className="inline-block bg-electricBlue text-white py-2 px-4 rounded-lg font-bold hover:bg-blue-700 transition duration-300">Leer Más</a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewsArticles;
