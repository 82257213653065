import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import TestimonialImage1 from '../assets/testi.jpg';
import TestimonialImage2 from '../assets/testi2.webp';

const testimonials = [
  {
    image: TestimonialImage1,
    name: 'Edwin Then',
    text: 'El contenido exclusivo y las canciones personalizadas me han encantado. ¡Son los mejores!',
  },
  {
    image: TestimonialImage2,
    name: 'María López',
    text: 'Ser miembro VIP ha sido una experiencia increíble. El acceso anticipado y los meet & greet virtuales son lo mejor.',
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const autoSlide = setInterval(() => {
      handleNext();
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(autoSlide); // Clean up the interval on component unmount
  }, []);

  return (
    <section className="bg-[#020c25] py-20 px-5 relative">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-2xl  md:text-3xl font-extrabold mb-6 text-white">Lo que Dicen Nuestros Fans</h2>
        <p className="text-lg mb-12 text-gray-200">Descubre cómo nuestra comunidad valora su experiencia.</p>

        <div className="relative h-64 overflow-hidden bg-blue-900 rounded-lg border-2 border-[#d1d5db]"> {/* Borde más delgado y color light */}
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
                index === currentIndex ? 'translate-x-0' : 'translate-x-full'
              }`}
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-full h-full  object-contain rounded-lg"
              />
              <div className="absolute inset-0 flex flex-col justify-center items-center bg-black/60 text-white p-6">
                <h3 className="text-xl font-semibold mb-4">{testimonial.name}</h3>
                <p className="italic text-center">{testimonial.text}</p>
              </div>
            </div>
          ))}

          {/* Navegación anterior */}
          <button
            type="button"
            className="absolute top-[90%] left-[1rem]  md:top-[50%] transform -translate-y-[50%] z-30 p-3  bg-[#09162e] rounded-full hover:bg-[#4b5563] focus:outline-none"
            onClick={handlePrev}
          >
            <FaChevronLeft size={24} className="text-white" />
          </button>

          {/* Navegación siguiente */}
          <button
            type="button"
            className="absolute top-[90%] right-[1rem] md:top-[50%] transform -translate-y-[50%] z-30 p-3 bg-[#0a162e] rounded-full hover:bg-[#4b5563] focus:outline-none"
            onClick={handleNext}
          >
            <FaChevronRight size={24} className="text-white" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
