import React, { useState } from 'react';
import FAQItem from '../components/faqItem';
import Footer from '../components/footer'; // Importa el componente Footer

const faqs = [
  {
    question: '¿Cómo funciona la IA en tus canciones?',
    answer: 'Utilizamos algoritmos avanzados de inteligencia artificial para interpretar nuestras letras originales y crear experiencias musicales únicas.'
  },
  {
    question: '¿Dónde puedo escuchar tu música?',
    answer: 'Nuestra música está disponible en todas las plataformas digitales como Spotify, Apple Music, Amazon Music, y más.'
  },
  {
    question: '¿Cómo puedo suscribirme a tus planes?',
    answer: 'Puedes suscribirte a nuestros planes a través de nuestra página de suscripción. Ofrecemos varios planes para adaptarnos a tus necesidades.'
  },
  {
    question: '¿Cómo puedo contactar con ustedes?',
    answer: 'Puedes contactarnos a través de nuestro formulario de contacto en la página web o enviando un correo electrónico a info@ecomusic.com.'
  },
  {
    question: '¿Ofrecen contenido exclusivo para suscriptores?',
    answer: 'Sí, ofrecemos contenido exclusivo, como canciones inéditas y acceso anticipado a lanzamientos, para nuestros suscriptores.'
  },
  {
    question: '¿Puedo cancelar mi suscripción en cualquier momento?',
    answer: 'Sí, puedes cancelar tu suscripción en cualquier momento a través de la configuración de tu cuenta.'
  },
  {
    question: '¿Cómo se graban las canciones?',
    answer: 'Las canciones se graban utilizando tecnología avanzada de IA en combinación con instrumentos y voces sintetizadas.'
  },
  {
    question: '¿Puedo colaborar con ustedes?',
    answer: 'Estamos abiertos a colaboraciones. Contáctanos para discutir posibles oportunidades de colaboración.'
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <section className="bg-gray-100 py-40 px-5 flex-grow">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-10 text-center text-electricBlue">Preguntas Frecuentes</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={index === openIndex}
                onClick={() => handleToggle(index)}
              />
            ))}
          </div>
        </div>
      </section>
      <Footer /> {/* Incluye el Footer al final de la página */}
    </div>
  );
};

export default FAQ;
