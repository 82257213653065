import React from 'react';

const ServicesSection = () => {
  const handleContactClick = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section id="services" className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8 text-darkBlue">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Composición de Canciones */}
          <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:border-2 hover:border-darkBlue">
            <h3 className="text-2xl font-semibold mb-4 text-darkBlue">Composición de Canciones</h3>
            <p className="text-gray-700 mb-6">
              Ofrecemos servicios de composición de canciones personalizadas para cualquier ocasión. Desde letras originales hasta melodías únicas, adaptamos nuestras creaciones a tus necesidades específicas.
            </p>
            <button
              onClick={handleContactClick}
              className="bg-electricBlue text-white py-3 px-6 rounded-lg font-bold hover:bg-blue-700 transition duration-300 transform hover:scale-105 hover:border-2 hover:border-darkBlue"
            >
              Contactar Ventas
            </button>
          </div>

          {/* Ventas de Letras de Canciones */}
          <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:border-2 hover:border-darkBlue">
            <h3 className="text-2xl whitespace-normal font-semibold mb-4 text-darkBlue">Ventas de Letras de Canciones</h3>
            <p className="text-gray-700 mb-6">
              Compra letras de canciones originales escritas por nuestros talentosos compositores. Perfectas para artistas, productores y creadores de contenido que buscan material exclusivo.
            </p>
            <button
              onClick={handleContactClick}
              className="bg-electricBlue text-white py-3 px-6 rounded-lg font-bold hover:bg-blue-700 transition duration-300 transform hover:scale-105 hover:border-2 hover:border-darkBlue"
            >
              Contactar Ventas
            </button>
          </div>

          {/* Licencias de Letras */}
          <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:border-2 hover:border-darkBlue">
            <h3 className="text-2xl font-semibold mb-4 text-darkBlue">Licencias de Letras</h3>
            <p className="text-gray-700 mb-6">
              Ofrecemos licencias para el uso de nuestras letras en tus proyectos. Asegúrate de obtener los derechos necesarios para usar nuestras obras en tus producciones.
            </p>
            <button
              onClick={handleContactClick}
              className="bg-electricBlue text-white py-3 px-6 rounded-lg font-bold hover:bg-blue-700 transition duration-300 transform hover:scale-105 hover:border-2 hover:border-darkBlue"
            >
              Contactar Ventas
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
