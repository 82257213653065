import { FaMusic, FaHeart, FaUsers, FaComments, FaGlobe, FaBell } from 'react-icons/fa';
import '../../src/App.css';

const FeaturesAndBenefits = () => {
  return (
    <section id="features" className="bg-[#020c25] py-20 px-5">
      <div className="max-w-7xl mx-auto text-center relative z-10">
        <h2 className="md:text-4xl text-2xl font-bold mb-10 text-white">Beneficios</h2>
        <p className="text-xl mb-16 text-white">Explora las ventajas de ser parte de nuestra comunidad musical y cómo puedes disfrutar de una experiencia única.</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {/* Feature 1 */}
          <div className="bg-[#003366] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:bg-[#004080] hover:outline hover:outline-2 hover:outline-white">
            <FaMusic size={50} className="text-white mx-auto mb-4" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Acceso Exclusivo a Nuevas Canciones</h3>
            <p className="text-white">Escucha mis últimos lanzamientos antes que nadie y disfruta de contenido exclusivo solo para seguidores.</p>
          </div>

          {/* Feature 2 */}
          <div className="bg-[#003366] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:bg-[#004080] hover:outline hover:outline-2 hover:outline-white">
            <FaHeart size={50} className="text-white mx-auto mb-4" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Conexión Directa Conmigo</h3>
            <p className="text-white">Participa en sesiones en vivo, entrevistas y eventos especiales donde podrás interactuar conmigo directamente.</p>
          </div>

          {/* Feature 3 */}
          <div className="bg-[#003366] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:bg-[#004080] hover:outline hover:outline-2 hover:outline-white">
            <FaUsers size={50} className="text-white mx-auto mb-4" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Comunidad de Fans</h3>
            <p className="text-white">Únete a una comunidad de fans apasionados que comparten tu amor por mi música y participa en foros y discusiones.</p>
          </div>

          {/* Feature 4 */}
          <div className="bg-[#003366] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:bg-[#004080] hover:outline hover:outline-2 hover:outline-white">
            <FaComments size={50} className="text-white mx-auto mb-4" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Comentarios y Recomendaciones</h3>
            <p className="text-white">Deja tus opiniones sobre las canciones, comparte recomendaciones y ayúdame a mejorar constantemente.</p>
          </div>

          {/* Feature 5 */}
          <div className="bg-[#003366] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:bg-[#004080] hover:outline hover:outline-2 hover:outline-white">
            <FaGlobe size={50} className="text-white mx-auto mb-4" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Acceso Desde Cualquier Lugar</h3>
            <p className="text-white">Disfruta de mi música y contenido exclusivo desde cualquier dispositivo, estés donde estés.</p>
          </div>

          {/* Feature 6 */}
          <div className="bg-[#003366] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:bg-[#004080] hover:outline hover:outline-2 hover:outline-white">
            <FaBell size={50} className="text-white mx-auto mb-4" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Notificaciones en Tiempo Real</h3>
            <p className="text-white">Recibe alertas instantáneas sobre nuevos lanzamientos, eventos y actualizaciones directamente en tu dispositivo.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesAndBenefits;
