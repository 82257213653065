import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import Footer from '../components/footer';

const NotFound = () => {
  return (
    <div className="flex flex-col min-h-screen py-20 bg-gray-50">
      <div className="flex-grow flex items-center justify-center py-20 px-6">
        <div className="text-center max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg border border-gray-200">
          <h1 className="text-5xl md:text-7xl font-extrabold text-darkBlue">404</h1>
          <p className="text-lg md:text-xl mt-4 text-gray-800">La página que buscas no está disponible.</p>
          <Link to="/" className="mt-8 inline-block bg-electricBlue text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 flex items-center justify-center">
            <FaHome className="mr-2 text-xl" /> Ir al Inicio
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
