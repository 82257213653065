import React, { useState, useRef } from 'react';
import Sidebar from '../components/sidebar';
import MusicPlayer from '../components/musicPlayer';
import AquiEstoy from '../assets/AquiEstoy.mp3';
import DosAmores from '../assets/dosAmores.mp3';
import Hero from '../assets/EcoMusic-Logo-Sec.svg';
import Amo from '../assets/Amo.mp3';
import Secretos from '../assets/Secretos.mp3';
import Milagro from '../assets/Milagro.mp3';

const App = () => {
  const [selectedSongIndex, setSelectedSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const songs = [
    { title: 'Aquí Estoy', url: AquiEstoy, image: Hero },
    { title: 'Dos Amores', url: DosAmores, image: Hero },
    { title: 'Te Amo', url: Amo, image: Hero },
    { title: 'Guardian de tus secretos', url: Secretos, image: Hero },
    { title: 'Pequeño Milagro', url: Milagro, image: Hero }
  ];

  const handleSelectSong = (song) => {
    const index = songs.findIndex(s => s.title === song.title);
    setSelectedSongIndex(index);
    setIsPlaying(true);
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleChangeSong = (index) => {
    setSelectedSongIndex(index);
    setIsPlaying(true);
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="flex bg-[#020c25] py-12 overflow-hidden h-screen">
      <Sidebar songs={songs} onSelectSong={handleSelectSong} />
      <MusicPlayer 
        songs={songs} 
        selectedSongIndex={selectedSongIndex} 
        onChangeSong={handleChangeSong} 
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        audioRef={audioRef}
      />
    </div>
  );
};

export default App;
