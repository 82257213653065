import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logoImage from '../assets/EcoMusic-Logo-Sec.svg';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import '../App.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null); // Para controlar qué submenú está abierto
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setDropdownOpen(null); // Cerrar el submenú al cerrar el menú
  };

  const handleDropdownToggle = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const handleScrollToContact = (event) => {
    event.preventDefault();
    if (window.location.pathname !== '/') {
      navigate('/', { state: { scrollToContact: true } });
    } else {
      const contactSection = document.getElementById('contact');
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
    closeMenu();
  };

  useEffect(() => {
    if (window.location.pathname === '/' && window.history.state?.scrollToContact) {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState({}, document.title); // Limpiar el estado después de hacer scroll
      }
    }
  }, []);

  return (
    <header className="bg-[#020c25] py-4 p-4 w-full fixed z-50 top-0">
      <div className="max-w-7xl mx-auto w-full flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/" className="relative">
            <img 
              src={logoImage} 
              alt="logo" 
              className="w-28 h-16 rounded-lg cursor-pointer border-r-4 border-transparent" 
            />
            <div 
              className="absolute top-0 right-0 h-14 w-1 bg-gradient-to-b from-electricBlue via-indigo-500 to-purple-600 rounded-lg"
            ></div>
          </Link>
        </div>

        <div className="flex-grow"></div>

        {/* Botón de menú en dispositivos móviles */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-electricBlue absolute right-4">
            {isOpen ? <FaTimes size={24} className="relative z-50" /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Menú desplegable en escritorio */}
        <nav ref={menuRef} className="relative flex-1">
          <ul className="hidden md:flex space-x-8">
            <li>
              <Link to="/" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={closeMenu}>Inicio</Link>
            </li>
            <li>
              <Link to="/about" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={closeMenu}>Acerca</Link>
            </li>
            <li>
              <Link to="/music" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={closeMenu}>Musica</Link>
            </li>
            <li>
              <a href="#contact" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={handleScrollToContact}>Contact</a>
            </li>

            {/* Enlace que despliega el submenú */}
            <li className="relative">
              <button
                className="flex items-center text-softGray hover:text-electricBlue active:text-electricBlue"
                onClick={() => handleDropdownToggle(0)} // Usar índice para controlar el submenú
                ref={dropdownRef}
              >
                Más
                <FaChevronDown className={`ml-2 transition-transform ${dropdownOpen === 0 ? 'rotate-180' : ''}`} />
              </button>
              {dropdownOpen === 0 && (
                <ul className="absolute top-full left-0 mt-2 w-48 bg-[#020c25] text-softGray border border-lightGray shadow-lg">
                  <li><Link to="/faq" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>FAQ</Link></li>
                  <li><Link to="/albums" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Albums</Link></li>
                  <li><Link to="/news" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Noticias</Link></li>
                  <li><Link to="/reviews" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Reviews</Link></li>
                  <li><Link to="/album-releases" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Programacion Lanzamientos</Link></li>
                </ul>
              )}
            </li>
          </ul>

          {/* Menú en dispositivos móviles */}
          <ul className={`md:hidden fixed top-0 left-0 w-full h-full  bg-[#020c25] flex flex-col items-center overflow-y-auto justify-center space-y-8 ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300`}>
            <li><Link to="/" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={closeMenu}>Inicio</Link></li>
            <li><Link to="/about" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={closeMenu}>Acerca</Link></li>
            <li><Link to="/music" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={closeMenu}>Musica</Link></li>
            <li><a href="#contact" className="text-softGray hover:text-electricBlue active:text-electricBlue" onClick={handleScrollToContact}>Contact</a></li>
            <li className="relative">
              <button
                className="flex items-center text-softGray hover:text-electricBlue"
                onClick={() => handleDropdownToggle(1)} // Índice diferente para el submenú en móvil
              >
                Más
                <FaChevronDown className={`ml-2 transition-transform ${dropdownOpen === 1 ? 'rotate-180' : ''}`} />
              </button>
              {dropdownOpen === 1 && (
                <ul className="absolute top-full left-0 mt-2 w-[10rem]  bg-[#020c25] text-softGray border border-lightGray shadow-lg">
                  <li><Link to="/faq" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>FAQ</Link></li>
                  <li><Link to="/albums" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Albums</Link></li>
                  <li><Link to="/news" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Noticias</Link></li>
                  <li><Link to="/reviews" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Reviews</Link></li>
                  <li><Link to="/album-releases" className="block px-4 py-2 hover:text-electricBlue" onClick={closeMenu}>Programacion Lanzamientos</Link></li>
                </ul>
              )}
            </li>
          </ul>
        </nav>

        {/* Botón de Patreon */}
        <div className="hidden md:block">
          <a href="https://www.patreon.com/your_channel" className="bg-electricBlue ml-12 text-white font-bold py-2 px-4 rounded-lg uppercase hover:bg-blue-700 transition duration-300">Patreon</a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
