import React from 'react';
import { FaEarlybirds, FaMusic, FaFilm, FaTshirt, FaVideo, FaCrown } from 'react-icons/fa';

const SubscriptionPlans = () => {
  return (
    <section id="subscription" className="bg-gradient-to-r from-[#e0e0e0] to-[#ffffff] text-gray-900 py-20 px-5">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-2xl md:text-4xl font-extrabold mb-10 text-gray-800">Comparativa de Planes</h2>
        <p className="text-lg mb-16 text-gray-600">Elige el plan que mejor se adapte a ti y disfruta de nuestras ofertas exclusivas.</p>

        {/* Table Container */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white text-gray-900 border border-gray-200 rounded-lg shadow-lg">
            <thead className="bg-[#020c25] text-white">
              <tr>
                <th className="py-3 px-6">Plan</th>
                <th className="py-3 px-6"><FaEarlybirds className="inline-block mr-2" />Acceso Anticipado</th>
                <th className="py-3 px-6"><FaMusic className="inline-block mr-2" />Canciones Personalizadas</th>
                <th className="py-3 px-6"><FaFilm className="inline-block mr-2" />Detrás de Escenas</th>
                <th className="py-3 px-6"><FaTshirt className="inline-block mr-2" />Merchandising Exclusivo</th>
                <th className="py-3 px-6"><FaVideo className="inline-block mr-2" />Meet & Greet Virtual</th>
                <th className="py-3 px-6"><FaCrown className="inline-block mr-2" />Membresía VIP</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-100 border-b hover:bg-gray-200 transition-colors duration-300">
                <td className="py-4 px-6 font-bold">Beneficios</td>
                <td className="py-4 px-6">✔️</td>
                <td className="py-4 px-6">✔️</td>
                <td className="py-4 px-6">✔️</td>
                <td className="py-4 px-6">✔️</td>
                <td className="py-4 px-6">✔️</td>
                <td className="py-4 px-6">✔️</td>
              </tr>
              <tr className="bg-gray-50 border-b hover:bg-gray-100 transition-colors duration-300">
                <td className="py-4 px-6 font-bold">Acceso a contenido</td>
                <td className="py-4 px-6">2 semanas antes</td>
                <td className="py-4 px-6">Personalizado</td>
                <td className="py-4 px-6">Videos exclusivos</td>
                <td className="py-4 px-6">Productos limitados</td>
                <td className="py-4 px-6">Sesiones en vivo</td>
                <td className="py-4 px-6">Todo incluido</td>
              </tr>
              <tr className="bg-gray-100 border-b hover:bg-gray-200 transition-colors duration-300">
                <td className="py-4 px-6 font-bold">Precio</td>
                <td className="py-4 px-6">$10/mes</td>
                <td className="py-4 px-6">$20/mes</td>
                <td className="py-4 px-6">$30/mes</td>
                <td className="py-4 px-6">$40/mes</td>
                <td className="py-4 px-6">$50/mes</td>
                <td className="py-4 px-6">$60/mes</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionPlans;
