import React from 'react';
import { Link } from 'react-router-dom';
import { FaClock, FaApple, FaAmazon, FaSpotify, FaYoutube } from 'react-icons/fa';
import Footer from '../components/footer'
const AlbumDetails = () => {
  const albumInfo = {
    title: "Ecos del Corazón",
    songs: [
      { title: "Guardián de tus secretos", duration: "3:45" },
      { title: "Entre dos amores", duration: "4:12" },
      { title: "Perdón en el adiós", duration: "3:30" },
      { title: "Vuelvo a ti", duration: "3:50" },
      { title: "Caminos cruzados", duration: "4:00" },
      { title: "Cruce de caminos", duration: "3:56" },
      { title: "Corazones en ruta", duration: "4:15" },
      { title: "Cielo de dos", duration: "3:40" },
      { title: "Cielo de dos 2", duration: "3:55" },
      { title: "Luz en la oscuridad", duration: "3:35" },
      { title: "Luz en la oscuridad 2", duration: "4:05" },
      { title: "Sombras del pasado", duration: "4:20" },
      { title: "Camino sola", duration: "3:50" },
      { title: "Nuevo amanecer", duration: "3:58" },
      { title: "Heaven for two", duration: "4:00" }
    ]
  };

  return (
    <div className="bg-[#020c25] min-h-screen text-white pt-40 pb-5 px-5 sm:px-10">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-10">
          <Link to="/albums" className="bg-electricBlue text-white transition-all hover:bg-blue-200 hover:text-blue-950 font-bold py-2 px-4 rounded">
            Volver a Álbumes
          </Link>
        </div>

        <h1 className="text-4xl font-bold text-center mb-6">{albumInfo.title}</h1>
        <div className="overflow-x-auto mb-8">
          <table className="w-full text-left  border-b-4 border-blue-400 table-auto hidden md:table">
            <thead className="text-sm font-semibold uppercase text-[#020c25] bg-lightGray">
              <tr>
                <th className="px-2 py-2">No.</th>
                <th className="px-2 py-2">Song</th>
                <th className="px-2 py-2">Duration</th>
                <th className="px-2 py-2">Apple Music</th>
                <th className="px-2 py-2">Amazon Music</th>
                <th className="px-2 py-2">Spotify</th>
                <th className="px-2 py-2">YouTube Music</th>
              </tr>
            </thead>
            <tbody className="text-lg font-medium divide-y divide-gray-700">
              {albumInfo.songs.map((song, index) => (
                <tr key={index} className="hover:bg-gray-700">
                  <td className="px-2 py-2">{index + 1}</td>
                  <td className="px-2 py-2">{song.title}</td>
                  <td className="px-2 py-2">{song.duration}</td>
                  <td className="px-2 py-2">
                    <a href={`https://music.apple.com/search?term=${encodeURIComponent(song.title)}`} className="bg-blue-300 text-[#020c25] py-1 px-2 rounded flex items-center gap-2 hover:bg-blue-700 hover:text-white transition duration-300">
                      <FaApple />
                      Escuchar
                    </a>
                  </td>
                  <td className="px-2 py-2">
                    <a href={`https://www.amazon.com/s?k=${encodeURIComponent(song.title)}`} className="bg-yellow-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-2 hover:bg-yellow-500 transition duration-300">
                      <FaAmazon />
                      Escuchar
                    </a>
                  </td>
                  <td className="px-2 py-2">
                    <a href={`https://open.spotify.com/search/${encodeURIComponent(song.title)}`} className="bg-green-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-2 hover:bg-green-500 transition duration-300">
                      <FaSpotify />
                      Escuchar
                    </a>
                  </td>
                  <td className="px-2 py-2">
                    <a href={`https://music.youtube.com/search?q=${encodeURIComponent(song.title)}`} className="bg-red-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-2 hover:bg-red-500 transition duration-300">
                      <FaYoutube />
                      Escuchar
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="md:hidden">
            {albumInfo.songs.map((song, index) => (
              <div key={index} className="bg-lightGray mb-3 p-4 rounded-lg shadow-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="text-lg font-bold text-[#020c25] mr-2">{index + 1}.</span>
                    <span className="text-[#020c25]">{song.title}</span>
                  </div>
                  <div className="flex items-center text-[#020c25] text-sm">
                    <FaClock className="mr-1" />
                    {song.duration}
                  </div>
                </div>
                <div className="mt-2 flex flex-wrap gap-2">
                  <a href={`https://music.apple.com/search?term=${encodeURIComponent(song.title)}`} className="bg-blue-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-1 hover:bg-gray-800 transition duration-300">
                    <FaApple size={20} />
                    Escuchar
                  </a>
                  <a href={`https://www.amazon.com/s?k=${encodeURIComponent(song.title)}`} className="bg-yellow-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-1 hover:bg-yellow-500 transition duration-300">
                    <FaAmazon size={20} />
                    Escuchar
                  </a>
                  <a href={`https://open.spotify.com/search/${encodeURIComponent(song.title)}`} className="bg-green-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-1 hover:bg-green-500 transition duration-300">
                    <FaSpotify size={20} />
                    Escuchar
                  </a>
                  <a href={`https://music.youtube.com/search?q=${encodeURIComponent(song.title)}`} className="bg-red-600 text-[#020c25] py-1 px-2 rounded flex items-center gap-1 hover:bg-red-500 transition duration-300">
                    <FaYoutube size={20} />
                    Escuchar
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AlbumDetails;
