import React from 'react';
import PropTypes from 'prop-types';

const ReviewItem = ({ author, text }) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-electricBlue">{author}</h3>
      <p className="text-gray-800 mt-2">{text}</p>
    </div>
  );
};

ReviewItem.propTypes = {
  author: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ReviewItem;
