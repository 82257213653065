import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { useNavigate } from 'react-router-dom';

const Modal = ({ show, onClose }) => {
  const modalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      gsap.fromTo(modalRef.current, { x: '100%', opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: 'power3.out' });
    } else {
      gsap.to(modalRef.current, { opacity: 0, duration: 1, ease: 'power3.in', onComplete: onClose });
    }
  }, [show, onClose]);

  const handleContactClick = () => {
    onClose();
    navigate('/contact');
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${show ? 'block' : 'hidden'}`}
      onClick={onClose}
      aria-hidden={!show}
      style={{ zIndex: 9999 }} // Asegura que el overlay esté sobre el resto de los elementos
    >
      <div
        ref={modalRef}
        className="bg-gradient-to-br from-[#020c25] to-[#007bff] text-white p-8 rounded-xl shadow-2xl max-w-md w-full mx-4 md:mx-0 transform overflow-y-auto max-h-96"
        onClick={(e) => e.stopPropagation()}
        role="dialog"
        aria-modal="true"
        style={{ zIndex: 10000 }} // Asegura que el contenido del modal esté sobre el overlay
      >
        <h2 className="text-2xl font-bold mb-4">¡Ocasión Especial en Ecomusic!</h2>
        <div className="grid gap-4 mb-4">
          <p className="bg-[#020c25] bg-opacity-80 p-3 rounded-lg shadow-md">🎵 <strong>Transforma tus ideas y emociones</strong> en canciones personalizadas, creadas y grabadas exclusivamente para ti.</p>
          <p className="bg-[#007bff] bg-opacity-80 p-3 rounded-lg shadow-md">🎉 ¡Haz que tus recuerdos suenen como nunca antes!</p>
          <p className="bg-[#020c25] bg-opacity-80 p-3 rounded-lg shadow-md">🎤 Tu canción, tu historia, tu voz.</p>
          <p className="bg-[#007bff] bg-opacity-80 p-3 rounded-lg shadow-md">🔗 Contáctanos hoy y comienza a crear la banda sonora de tu vida: Visita <a href="https://ecomusic.com" className="underline">ecomusic.com</a>, escríbenos al WhatsApp 809-305-2197, o envíanos un correo a <a href="mailto:info.ecomusic@ninestarunited.com" className="underline">info@ecomusic.com</a>. ¡Estamos aquí para ayudarte a crear algo extraordinario!</p>
        </div>
        <div className="flex justify-end space-x-4">
          <button 
            onClick={onClose} 
            className="bg-red-600 text-white py-2 px-4 rounded-lg font-semibold hover:bg-red-700 transition duration-300"
          >
            Cerrar
          </button>
          <button 
            onClick={handleContactClick} 
            className="bg-green-500 text-white py-2 px-4 rounded-lg font-semibold hover:bg-green-600 transition duration-300"
          >
            Contáctanos
          </button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
