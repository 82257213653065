import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FaPlay, FaPause, FaStepForward, FaStepBackward } from 'react-icons/fa';

const MusicPlayer = ({ songs, selectedSongIndex, onChangeSong }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    if (hasNavigated) {
      audioRef.current.play();
      setIsPlaying(true);
    }

    const handleKeyDown = (e) => {
      switch (e.key) {
        case ' ':
          e.preventDefault();
          handlePlayPause();
          break;
        case 'ArrowRight':
          e.preventDefault();
          handleNextSong();
          break;
        case 'ArrowLeft':
          e.preventDefault();
          handlePrevSong();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSongIndex, hasNavigated]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = songs[selectedSongIndex].url;
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [selectedSongIndex, isPlaying, songs]);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handlePrevSong = () => {
    setHasNavigated(true);
    onChangeSong((selectedSongIndex - 1 + songs.length) % songs.length);
  };

  const handleNextSong = () => {
    setHasNavigated(true);
    onChangeSong((selectedSongIndex + 1) % songs.length);
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    setCurrentTime(currentTime);
    setProgress((currentTime / duration) * 100);
  };

  const handleProgressClick = (e) => {
    const progressBar = e.target.getBoundingClientRect();
    const clickX = e.clientX - progressBar.left;
    const newTime = (clickX / progressBar.width) * audioRef.current.duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    setProgress((clickX / progressBar.width) * 100);
  };

  const handleSongEnd = () => {
    handleNextSong();
  };

  const updatePlayPauseIcon = () => {
    setIsPlaying(!audioRef.current.paused);
  };

  return (
    <div 
      className="flex-1 flex flex-col justify-end bg-cover bg-center bg-darkBlue text-white p-6 rounded-lg shadow-lg relative"
      style={{ backgroundImage: `url(${songs[selectedSongIndex].image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <h2 className="text-2xl font-bold mb-4 bg-black bg-opacity-70 p-3 rounded-lg shadow-lg text-center">
        {songs[selectedSongIndex].title}
      </h2>
      <audio 
        ref={audioRef} 
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleSongEnd}
        onPlay={updatePlayPauseIcon}
        onPause={updatePlayPauseIcon}
      >
        Your browser does not support the audio element.
      </audio>
      <div className="w-full bg-gray-700 rounded h-2 cursor-pointer mb-4" onClick={handleProgressClick}>
        <div className="bg-electricBlue h-2 rounded" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="flex items-center justify-center space-x-6 mb-4">
        <button onClick={handlePrevSong} className="text-white bg-gray-800 p-3 rounded-full hover:bg-gray-700 transition transform hover:scale-110">
          <FaStepBackward size={20} />
        </button>
        <button onClick={handlePlayPause} className="text-white bg-gray-800 p-3 rounded-full hover:bg-gray-700 transition transform hover:scale-110">
          {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
        </button>
        <button onClick={handleNextSong} className="text-white bg-gray-800 p-3 rounded-full hover:bg-gray-700 transition transform hover:scale-110">
          <FaStepForward size={20} />
        </button>
      </div>
    </div>
  );
};

MusicPlayer.propTypes = {
  songs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedSongIndex: PropTypes.number.isRequired,
  onChangeSong: PropTypes.func.isRequired
};

export default MusicPlayer;
