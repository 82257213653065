import React, { useState, useEffect } from 'react';
import { FaMusic, FaTimes, FaHeart, FaApple, FaAmazon, FaSpotify, FaYoutube } from 'react-icons/fa';
import albumImage from '../assets/hero.jpg';
import Footer from '../components/footer';

const albums = [
  {
    id: 1,
    title: 'Ecos del Corazón',
    image: albumImage,
    link: '/albums/ecos-del-corazon'
  },
  {
    id: 2,
    title: 'Destellos del destino',
    image: albumImage,
    link: '/albums/destellos-del-destino'
  },
  {
    id: 3,
    title: 'Caminos entrelazados',
    image: albumImage,
    link: '/albums/caminos-entrelazados'
  },
  {
    id: 4,
    title: 'Susurros del viento',
    image: albumImage,
    link: '/albums/susurro-del-viento'
  }
];

const Albums = () => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Delay the opening of the modal by 2 seconds
    const timer = setTimeout(() => {
      setModalVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <div className="bg-lightGray min-h-screen pt-12 text-gray-900">
      <section className="py-16 px-4 md:px-8 lg:px-16">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center text-darkBlue">Nuestros Álbumes</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {albums.map(album => (
              <div key={album.id} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                <img src={album.image} alt={album.title} className="w-full h-48 object-cover rounded-t-lg mb-6" />
                <h3 className="text-2xl font-semibold mb-4 text-center text-darkBlue">{album.title}</h3>
                <div className="flex flex-col gap-4">
                  <a href={`https://music.apple.com/search?term=${encodeURIComponent(album.title)}`} className="flex items-center justify-center bg-gray-900 text-white py-2 px-4 rounded-lg font-bold hover:bg-gray-800 transition duration-300"><FaApple className="mr-2" /> Apple Music</a>
                  <a href={`https://www.amazon.com/s?k=${encodeURIComponent(album.title)}`} className="flex items-center justify-center bg-yellow-600 text-white py-2 px-4 rounded-lg font-bold hover:bg-yellow-500 transition duration-300"><FaAmazon className="mr-2" /> Amazon Music</a>
                  <a href={`https://open.spotify.com/search/${encodeURIComponent(album.title)}`} className="flex items-center justify-center bg-green-600 text-white py-2 px-4 rounded-lg font-bold hover:bg-green-500 transition duration-300"><FaSpotify className="mr-2" /> Spotify</a>
                  <a href={`https://music.youtube.com/search?q=${encodeURIComponent(album.title)}`} className="flex items-center justify-center bg-red-600 text-white py-2 px-4 rounded-lg font-bold hover:bg-red-500 transition duration-300"><FaYoutube className="mr-2" /> YouTube Music</a>
                  <a href={album.link} className="bg-electricBlue text-white py-3 px-6 rounded-lg font-bold text-center hover:bg-blue-700 transition duration-300">Ver canciones del álbum</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {modalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-gradient-to-r from-darkBlue to-electricBlue p-6 rounded-lg max-w-lg mx-auto text-white relative">
            <button onClick={handleClose} className="absolute top-4 right-4 text-white text-xl"><FaTimes /></button>
            <p className="text-lg">
              Si quieres escuchar un fragmento de las canciones, navega a la sección 'Music' en el menú, o haz clic en los botones para escuchar el álbum completo en tu plataforma favorita. 
              <br /> Gracias por tu apoyo, música hecha con <FaHeart className="inline text-red-600" />
            </p>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Albums;
