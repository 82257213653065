import React from 'react';
import { FaChevronLeft, FaChevronRight, FaSpotify } from 'react-icons/fa';
import ReleaseImage1 from '../../assets/hero.png';
import ReleaseImage2 from '../../assets/guitar.png';
import ReleaseImage3 from '../../assets/her2.png';
import ReleaseImage4 from '../../assets/guitar.png';
import ReleaseImage5 from '../../assets/hero.png';

const releases = [
  { title: "Caminos Entrelazados", description: "Un viaje musical a través de caminos entrelazados que exploran nuevas dimensiones del sonido y la emoción.", image: ReleaseImage1, link: "https://www.spotify.com/album1" },
  { title: "Destellos del Destino", description: "Un álbum que ilumina el camino con destellos del destino, combinando ritmos cautivadores y letras profundas.", image: ReleaseImage2, link: "https://www.spotify.com/album2" },
  { title: "ECOS DEL DESTINO", description: "Explora los ecos que resuenan a lo largo del destino, una mezcla de sonidos que evocan recuerdos y emociones.", image: ReleaseImage3, link: "https://www.spotify.com/album3" },
  { title: "Rieles de Amor", description: "Un viaje melódico a través de los rieles del amor, con composiciones que capturan la esencia del romance y la pasión.", image: ReleaseImage4, link: "https://www.spotify.com/album4" },
  { title: "Susurros del Viento", description: "Deja que los susurros del viento te envuelvan en un ambiente de calma y serenidad con este álbum introspectivo.", image: ReleaseImage5, link: "https://www.spotify.com/album5" },
];

const DesktopCarousel = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const itemsPerPage = 2; // Número de álbumes por slide en desktop
  const totalSlides = Math.ceil(releases.length / itemsPerPage);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
    );
  };

  const visibleReleases = releases.slice(currentIndex * itemsPerPage, (currentIndex + 1) * itemsPerPage);

  return (
    <section className="bg-light-blue text-gray-900 py-12 px-5">
      <div className="max-w-6xl mx-auto">
        <div className="relative bg-white shadow-lg rounded-md p-6">
          {/* Contenedor de los álbumes */}
          <div className="relative overflow-hidden">
            <div className="flex transition-all duration-500 ease-in-out">
              {visibleReleases.map((release, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/2 flex-shrink-0 flex flex-col items-center px-4"
                >
                  <img
                    src={release.image}
                    alt={release.title}
                    className="w-full h-64 object-cover rounded-md mb-4"
                  />
                  <div className="text-center">
                    <h3 className="text-2xl font-semibold mb-4 text-electricBlue">{release.title}</h3>
                    <p className="mb-4 text-gray-700">{release.description}</p>
                    <a
                      href={release.link}
                      className="inline-flex items-center bg-electricBlue py-3 px-6 rounded-lg font-bold text-white hover:bg-blue-700 transition duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaSpotify className="mr-2" /> Escuchar Ahora
                    </a>
                  </div>
                </div>
              ))}
            </div>

            {/* Controles de navegación */}
            <button
              onClick={handlePrevClick}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl text-electricBlue bg-white p-2 rounded-full shadow-lg"
            >
              <FaChevronLeft />
            </button>
            <button
              onClick={handleNextClick}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 text-2xl text-electricBlue bg-white p-2 rounded-full shadow-lg"
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesktopCarousel;
