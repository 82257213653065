import React from 'react';
import NewsArticles from '../components/news';
import Footer from '../components/footer';

const NewsPage = () => {
  return (
    <div>
     
      <NewsArticles />
      <Footer />
    </div>
  );
};

export default NewsPage;
