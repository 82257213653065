import React from 'react';
import PropTypes from 'prop-types';
import { FaMusic } from 'react-icons/fa';

const Sidebar = ({ songs, onSelectSong }) => {
  return (
    <div className="relative w-1/6 min-w-32 mt-12 h-full bg-[#020c25] text-white p-4 flex flex-col md:w-1/6 md:min-w-60 sm:w-1/12 sm:min-w-30">
      <div className="absolute top-0 right-0 h-full w-2 bg-gradient-to-b from-electricBlue via-indigo-500 to-purple-600 rounded-l-lg"></div>
      <h2 className="text-lg font-bold mb-4 flex items-center">
        <FaMusic className="mr-2" /> Songs
      </h2>
      <ul className="overflow-y-auto flex-grow">
        {songs.map((song, index) => (
          <li
            key={index}
            className="mb-3 cursor-pointer hover:bg-blue-700 p-2 rounded flex items-center transition-transform transform hover:scale-105"
            onClick={() => onSelectSong(song)}
          >
            <FaMusic className="mr-2" /> {song.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

Sidebar.propTypes = {
  songs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelectSong: PropTypes.func.isRequired
};

export default Sidebar;
