import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ReviewForm = ({ onAddReview }) => {
  const [author, setAuthor] = useState('');
  const [text, setText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (author && text) {
      onAddReview({ author, text });
      setAuthor('');
      setText('');
    }
  };

  return (
    <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="author" className="block text-sm font-medium text-darkBlue">Nombre</label>
          <input
            id="author"
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-darkBlue rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-darkBlue"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="text" className="block text-sm font-medium text-darkBlue">Reseña</label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-darkBlue rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-darkBlue"
            rows="4"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-[#020c25] text-white px-4 py-2 rounded-md hover:bg-electricBlue transition duration-200"
        >
          Enviar Reseña
        </button>
      </form>
    </div>
  );
};

ReviewForm.propTypes = {
  onAddReview: PropTypes.func.isRequired,
};

export default ReviewForm;
