import React, { useEffect } from 'react';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';


const AboutUs = () => {
  useEffect(() => {
    // Desplazar al inicio cuando el componente se monta
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="flex flex-col min-h-screen pt-16 bg-gray-100">
      <div className="flex-grow py-16 px-4 md:px-8 lg:px-16">
        <div className="max-w-7xl mx-auto">
          {/* Encabezado */}
                <header className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-electricBlue mb-6">
          ¿Quiénes Somos?
        </h1>
        <p className="text-xl text-gray-800 max-w-3xl mx-auto">
          En <span className="text-darkBlue font-semibold">Ecomusic</span>, una subsidiaria de{' '}
          <a
            href="https://www.ninestarunited.com"
            className="text-electricBlue underline hover:text-electricBlue-dark transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            NineStarUnited
          </a>, estamos a la vanguardia de la innovación musical. Nuestro objetivo es ofrecer experiencias únicas, combinando inteligencia artificial y creatividad para revolucionar la forma en que experimentas la música.
        </p>
      </header>


          {/* Contenido Principal */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="space-y-12">
              {/* Misión */}
              <section>
                <h2 className="text-4xl font-semibold text-darkBlue mb-4">Misión</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Nuestra misión es revolucionar la industria musical utilizando inteligencia artificial para interpretar canciones con letras originales. Ofrecemos una experiencia musical única y accesible para todos, transformando la creación y la experiencia de la música.
                </p>
              </section>

              {/* Visión */}
              <section>
                <h2 className="text-4xl font-semibold text-darkBlue mb-4">Visión</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Nuestra visión es ser líderes en la integración de tecnología y creatividad en la música. Buscamos inspirar a artistas y oyentes por igual, redefiniendo los límites de la creación musical y estableciendo nuevos estándares en la industria.
                </p>
              </section>

              {/* Valores */}
              <section>
                <h2 className="text-4xl font-semibold text-darkBlue mb-4">Valores</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 space-y-3">
                  <li><strong>Innovación:</strong> Promovemos el uso de tecnología avanzada para crear música.</li>
                  <li><strong>Creatividad:</strong> Valoramos la originalidad y la expresión artística.</li>
                  <li><strong>Accesibilidad:</strong> Queremos que nuestra música sea accesible para todos.</li>
                  <li><strong>Calidad:</strong> Nos esforzamos por producir música de la más alta calidad.</li>
                </ul>
              </section>
            </div>

            <div className="space-y-12">
              {/* Información Adicional */}
              <section>
                <h2 className="text-4xl font-semibold text-darkBlue mb-4">Acerca de Ecomusic</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Ecomusic es una subsidiaria de <a href="https://www.ninestarunited.com" className="text-electricBlue underline hover:text-blue-700" target="_blank" rel="noopener noreferrer">NineStarUnited</a>. Nos especializamos en la fusión de tecnología y creatividad para ofrecer soluciones innovadoras en la industria musical. Nuestro equipo de expertos está comprometido en anticipar las tendencias y satisfacer las necesidades del mercado.
                </p>
              </section>

              {/* Disclaimer */}
              <section>
                <h2 className="text-4xl font-semibold text-darkBlue mb-4">Disclaimer</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Todas las letras de nuestras canciones están protegidas por derechos de autor. Está prohibida la reproducción total o parcial de las letras sin el consentimiento explícito del autor. Para obtener más información sobre licencias y derechos, por favor contáctanos.
                </p>
              </section>
              <button
           
              className="bg-electricBlue text-white py-3 px-6 rounded-lg font-bold hover:bg-blue-700 transition duration-300 transform hover:scale-105 hover:border-2 hover:border-darkBlue"
            ><Link to="/contact">Contactar Ventas</Link>
  
            </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
